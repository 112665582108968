import React from 'react'
import Marco from '../components/marco'
import Container from '@material-ui/core/Container'
import Minipaginas from '../components/minipaginas'
import Minipagina from '../components/minipagina'
import FotoConLeyenda from '../components/foto-con-leyenda'
import Comentarios from '../components/comentarios'
import TextoEnMinipagina from '../components/texto-en-minipagina'
import { Tips } from '../components/estilos/estilos-de-articulo'
import EspaciadorVertical from '../components/espaciador-vertical'
import { graphql } from 'gatsby'
import Navegar from '../components/navegar'

// #region GraphQL
export const query = graphql`
  {
    allTipsValijas2Yaml {
      edges {
        node {
          nombre
          origen {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
// #endregion

const TipsValijas2 = ({ data, location }) => {
  // eslint-disable-next-line no-unused-vars
  const toFluid = nombre => {
    const edges = data.allTipsValijas2Yaml.edges
    const found = edges.find(x => x.node.nombre === nombre)
    const fluid = found.node.origen.childImageSharp.fluid
    return fluid
  }
  return (
    <Marco>
      <Tips>
        <Container maxWidth="xl">
          <Minipaginas>
            <Minipagina>
              <FotoConLeyenda
                zoom
                fluid={toFluid('imgblogtvalijas2enfiumicinoconvalijasjpg')}
                title="En el aeropuerto Leonardo da Vinci con valijas. Fiumicino."
                leyenda="En el aeropuerto Leonardo da Vinci con valijas. Fiumicino."
              />
            </Minipagina>
            <Minipagina>
              <TextoEnMinipagina>
                <h2>¿Con cuántas valijas viajo?</h2>
                <p> Cómo acostumbrarme mal me significó grandes sufrimientos. </p>
                <p>
                  En mis primeros viajes iba a la casa de mi novio y él me iba a buscar en su auto
                  al aeropuerto, así que llevaba dos enormes valijas de 25 kg, aunque pesaban los
                  reglamentarios 23 kg, además llevaba una de mano de 8 kg, una mochila y
                  riñonera!!! En ese entonces Alitalia te permitía llevar dos valijas de 23 kg en la
                  bodega.
                </p>
                <p>
                  Cuando volví para hacer mi viaje soñado llevé una sola valija de 23 kg y ¿qué
                  sucedió? ¡Me arruinó el viaje! Cuando vas a estar viajando todo el tiempo de un
                  lado para otro y tomando trenes es imposible arrastrar semejante valija.
                </p>
                <p>
                  Y la mala noticia: ¡las estaciones de trenes están llenas de escaleras y no son
                  mecánicas! Hay ascensores pero casi nunca funcionan ¡Olvídate de los ascensores! Y
                  a los trenes siempre los estoy corriendo. Es que hay que hacer combinaciones y a
                  veces tienes 5 minutos entre un tren y el siguiente y están en distintos andenes
                  (escaleras mediante). Te imaginas corriendo por las escaleras con una valija tan
                  pesada!!! Un horror. Llegué a pensar en abandonar la valija.
                </p>
              </TextoEnMinipagina>
            </Minipagina>
            <Minipagina>
              <TextoEnMinipagina>
                <p>
                  Y más te cuento, no pude alojarme en Venecia porque los hoteles no tenían ascensor
                  y yo no podía subir ese monstruo, ni siquiera podía caminar hasta el hotel desde
                  la terminal que está en las afueras ¡canales de por medio! Tuve que alojarme en
                  Mestre, en tierra firme.
                </p>
                <p>
                  En otro momento del viaje, andaba en auto, no podía subir la valija al baúl del
                  auto por lo pesada que era ¿Sabés qué llegué a hacer? Puse en una bolsita lo
                  indipensable para pasar la noche en el hotel y dejé la valija en el auto. Y al
                  final de tanto sufrimiento constaté que no usé casi nada de lo que había llevado.
                </p>
                <p> Entonces ¿Cómo viajo? ¿Como en la primera foto? </p>
                <h3>Viajando con solo una valijita de 8 kg.</h3> <p> O ¿Así? </p>
                <p>
                  Acá estoy en Arlanda, el aeropuerto de Estocolmo ¡Feliz! ¡Con solo una valija de
                  cabina de 8 kg! Era un viaje por un fin de semana. Pero es lo ideal. Una vez lo
                  logré y llevé solamente esa valija a Roma. Iba en verano. Llevé poca ropa y jabón
                  de lavar. Y Listo!!!! Valija de cabina de 4 ruedaitas, de 360°, son las que tienen
                  libertad de moverse en cualquier sentido.
                </p>
                <p>
                  Si no obstante mis sugerencias quieres llevar una valija de 23 kg, elije las que
                  tienen 4 rueditas, 360° y llévala casi vacía. Algo vas a querer comprar y
                  necesitas espacio para tus compras.
                </p>
                <p> Post, abril 19, 2018 </p>
              </TextoEnMinipagina>
            </Minipagina>
            <Minipagina>
              <FotoConLeyenda
                zoom
                fluid={toFluid('imgblogtvalijas3enarlandaestocolmojpg')}
                title="Feliz llegando a Arlanda, el aeropuerto de Estocolmo con solo una valijita."
                leyenda="Feliz llegando a Arlanda, el aeropuerto de Estocolmo con solo una valijita."
              />
            </Minipagina>
          </Minipaginas>
          <Navegar location={location} />
          <Comentarios />
          <EspaciadorVertical altura={36} />
        </Container>
      </Tips>
    </Marco>
  )
}
export default TipsValijas2
